<script setup>
import { onMounted, ref, toRaw } from "vue";
import request from "local-vue-common/request/request";

const _ = require("lodash");

const showDialog = ref(false);
//const selectUsers = ref([]);
const userSelections = ref([[], []]);
// eslint-disable-next-line no-undef
const emit = defineEmits(["cancel", "accept"]);
// eslint-disable-next-line no-undef
const props = defineProps({
  record: {},
  editMode: {},
  name: {},
});

function acceptSelect() {
  const selectUserIds = [];
  const selectUserNames = [];
  for (let i = 0; i < userSelections.value[1].length; i++) {
    const item = userSelections.value[1][i];
    selectUserIds.push(item.id);
    selectUserNames.push(item.displayName);
  }
  emit("accept", selectUserIds, selectUserNames);
}

function cancelSelect() {
  emit("cancel");
}

const loadPipelineUser = () => {
  let selectIds = [];
  if (props.editMode == "update") selectIds = toRaw(props.record.users);

  let url = "/api/pipeline/user";
  request.post(url, { users: selectIds }).then((resp) => {
    if (resp.data.success) {
      const ret = resp.data.content;
      const a = _.differenceWith(ret["available"], ret["current"], (a, b) => {
        return a.id == b.id;
      });
      userSelections.value = [a, ret["current"]];
    } else {
      userSelections.value = [[], []];
    }
  });
};

onMounted(() => {
  showDialog.value = true;
  loadPipelineUser();
});
</script>

<template>
  <Dialog
    :visible="showDialog"
    :closable="false"
    class="col-12 md:col-6 select-dialog"
    style="padding: 0"
  >
    <template #header></template>
    <PickList v-model="userSelections" data-key="id" list-style="height:400px;">
      <template #sourceheader>可选用户</template>
      <template #targetheader>当前用户</template>
      <template #item="slotProps">
        <span>{{ slotProps.item.displayName }}</span>
      </template>
    </PickList>
    <template #footer>
      <div class="col-12 text-center">
        <Button class="mr-2" label="确定" @click="acceptSelect"></Button>
        <Button label="取消" @click="cancelSelect"></Button>
      </div>
    </template>
  </Dialog>
</template>

<style scoped></style>
