import request from '../request/request.js';

class DictManager_ {

    constructor() {
        //console.log("===DictManager")
        this.dictMap = {};
        this.dictMapList = {};
    }

    load(url) {

        request.get(url).then(resp=>{

            const dictList = resp.data.content;
            for(let i = 0; i < dictList.length; i++) {
                const dict = dictList[i];
                this.dictMap[dict.id]=dict;
            }
        });
    }

    addDict(dictId,dict) {
        this.dictMap[dictId]=dict;
    }

    getDict(dictId) {
        const dict = this.dictMap[dictId];
        return dict;
    }

    getDictText(dictId,itemId) {
        const dict = this.dictMap[dictId];
        return dict[itemId];
    }
}

const DictManager = new DictManager_();

export { DictManager };
