import { isRef, toRaw, isProxy } from "vue";

export default function valueToRaw(value) {
  if (value) {
    if (isRef(value)) {
      return toRaw(value);
    }
    if (isProxy(value)) {
      return toRaw(value);
    }
  }
  return value;
}
