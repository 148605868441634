<script>
import { h, resolveComponent,toRaw } from "vue";
import Dialog from "primevue/dialog";
import valueToRaw from "@/commons/valueToRaw.js"
const _ = require("lodash")
//import PipelineParamPanel from "@/commons/components/form/PipelineParamPanel";
//import RolePermissionPanel from "@/commons/components/form/RolePermissionPanel";
//import PipelineNodePanel from "@/commons/components/form/PipelineNodePanel";

export default {
  name: "PopupSelectField",
  components: {
    Dialog //, PipelineParamPanel,RolePermissionPanel,PipelineNodePanel
  },
  props: {
    record: {},
    name: {},
    valueName: {},
    textName: {},
    label: {},
    editMode: {},
    selectPanelName: {},
    selectButtonLabel: {},
    selectPanelConfig: {
      default: {}
    },
    updateCount : {
        default: 0
    }
  },
  data() {

    return {
      showSelectDialog: false,
      localRecord: null,
      localUpdateCount: 1,
      value: this.getFieldValue(),
      textValue : ""
    }
  },
  //emits: ['update:modelValue'],

  watch: {
    record() {

         this.localRecord = _.cloneDeep(toRaw(this.record))
         this.value = this.getFieldValue();
         this.textValue = this.getTextValue();

         //console.log("===PopupSelectField watch record1")

    },
    updateCount(v,oldVal) {
      //if (v == oldVal)
      //  return;
      this.value = this.getFieldValue();
      this.textValue = this.getTextValue();
      this.localRecord = _.cloneDeep(toRaw(this.record))
        //console.log("===PopupSelectField watch record2")

        //console.log("===PopupSelectField watch", v, oldVal)


    }
    ,
    showSelectDialog() {

    }

  },
  computed: {

      textValue1() {
         return this.getTextValue();
      }
  },
  mounted() {
    this.localRecord =  _.cloneDeep(toRaw(this.record))
    //console.log("===PopSelectField mounted ",this.localRecord)
    //this.changeRecord = _.cloneDeep(this.record)
    //this.changeRecord = valueToRaw(this.changeRecord)
      
  },
  methods: {

    getFieldValue() {
      if (this.localRecord) {
        //console.log("===PopupSelectField fld get by record", this.localRecord)
        return this.localRecord[this.name]
      }
      return "";//this.getStoreVal();
    },
    getTextValue() {
      if (this.localRecord) {
        const rec = valueToRaw(this.localRecord)
        const k = this.textName ? this.textName : this.name;
        const v = rec[k]
        //console.log("===PopupSelectField  txt get by record", k, v, rec)
        return v;
      }
      return "";//this.getStoreVal();
    },
    openSelect() {
      //console.log("===PopupSelectField  openSelect")
      this.showSelectDialog = true;
    },
    closeSelect() {
      //console.log("===PopupSelectField closeSelect")
      this.showSelectDialog = false;
    },

    acceptSelect(value, valueText) {
      this.showSelectDialog = false;
      if (valueText) {
        this.localRecord[this.textName] = valueText
        this.localUpdateCount++;
      }
      this.localRecord[this.name] = value
      this.$emit('valueChange', value,this.name)
    },

    createPanel() {
      let panelConfig = {
        record: this.localRecord,
        name: this.name,
        title: this.label,
        editMode: this.editMode,
        onCancel: () => this.closeSelect(),
        onAccept: this.acceptSelect
      }
      panelConfig = _.merge(panelConfig, this.selectPanelConfig)
      return h(resolveComponent(this.selectPanelName), panelConfig)
    },
    createFooter() {
      /*
         <div class="grid">
             <div class="col text-center">
               <Button label="ok" class="w-6"></Button>
             </div>
           <div class="col text-center">
             <Button label="cancel" class="w-6"></Button>
           </div>

         </div>
       */

    },

    createDialog1() {
      return h(Dialog, {

        visible: this.showSelectDialog,
        'onUpdate:visible': value => {
          //console.log("===onUpdate:modelValue")
          this.$emit('update:visible', value)
        },
        modal: true,
        closable: false,
        class: 'col-12 md:col-6 select-dialog',
      }, {
        header: () => h('h2', null, this.label),
        footer:
          h('span', { class: 'hidden' }, ''),
        default: () => this.createPanel()
      }
      )

    },
    createDialog() {

      return this.createPanel()
    },
    createField() {
      return h('div', {
        class: 'p-inputgroup'
      },
        [
          h(resolveComponent('InputText'), {
            //readonly: true,
            type: 'text',
            modelValue: this.textValue,
            //value: this.textValue
          }),
          h(resolveComponent('Button'), {
            label: this.selectButtonLabel ? this.selectButtonLabel : '...',
            onClick: () => {
              //console.log("===openSelect")
              this.openSelect()
            }
          })
        ]
      )
    }
  },
  render() {
    let childComponents = [];
    // eslint-disable-next-line no-debugger
    //debugger
    childComponents.push(h('label', null, this.label))
    childComponents.push(this.createField())
    if (this.showSelectDialog)
      childComponents.push(this.createDialog())
    return h('div', { class: 'field' }, childComponents)
  }
}
</script>

<style scoped></style>