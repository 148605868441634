import request from "local-vue-common/request/request";
import { addLoginSuccessHandler } from "local-vue-common/authority/authority";
let checkParamsConfig = {};
let checkMethods = [];
let loadParamsFlag = false;
export function getCheckParams(checkMethod) {
  const cfg = checkParamsConfig;
  return cfg[checkMethod];
}
/*
/api/pipeline/checkMethod
/api/pipeline/checkParam/{configName}
*/
const pipelineUrl = "/api/view/pipeline";

export function loadCheckParams() {
  if (loadParamsFlag) return;
  const url = pipelineUrl;
  request.get(url + "/checkMethod").then((resp) => {
    checkMethods = resp.data;

    for (let i = 0; i < checkMethods.length; i++) {
      const checkMethodId = checkMethods[i].value;
      const aurl = url + "/checkParam/" + checkMethodId;
      request.get(aurl).then((resp) => {
        loadParamsFlag = true;
        checkParamsConfig[checkMethodId] = resp.data;
      });
    }
  });
}

export function getCheckMethods() {
  return checkMethods;
}

//eslint-disable-next-line
export default function install(app) {
  //if (!url) url = "/api/pipeline";

  addLoginSuccessHandler(loadCheckParams);
  //loadCheckParams(url);
}
