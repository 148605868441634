<template>
  <div class="field">
    <label>{{ label }}</label>
    <InputText :name="name" type="text" :value="val" readonly />
  </div>
</template>

<script>

export default {
  name: "DisplayField",
  props: {
    label: {},
    name: {},
    updateCount: {},
    value : {
      default : undefined
    },
    record: {},
  },

  data() {

    return {
        val : this.getFieldValue()
    }
  },
  watch: {
    record(v, oldVal) {
      if ( v == oldVal)
           return;
      this.val = this.getFieldValue();
      //console.log("===watch", v, oldVal)
    }

  },

  methods: {

    getFieldValue() {
        if ( this.record ) {
            //console.log("===get by record",this.record)
            return this.record[this.name]
        }
        return "";//this.getStoreVal();
    },
    /*
    getStoreVal() {
      let store = CrudShareData.get(this.storeId)
      if ( !store )
          return "";
      const r = store.getCurrentRecord();

      console.log("===get by store", r)
      return r[this.name]
    },*/
    handlerUpdate(event) {
      //this.val = event.target.value
      //console.log("=valueChange=")
      this.$emit('valueChange', event.target.value, this.name)
    }
  }
}
</script>

<style scoped>

</style>