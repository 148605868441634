let localStorageOptions = { prefix: "_" };
let localStorage = window.localStorage;

function localStorageSet(name, value) {

    localStorage.setItem(localStorageOptions.prefix + name, value);
}

function localStorageGet(name) {

    return localStorage.getItem(localStorageOptions.prefix + name);
}

function localStorageRemove(name) {

    localStorage.removeItem(localStorageOptions.prefix + name);
}

function useLocalStorage(app, options) {
    if (options.prefix) {
        localStorageOptions = {
            prefix: options.prefix + "_"
        };
    }
    //app.provide('storage', options)
}


var localstorage = {
    install: (app, options) => {
        if ( options.prefix)
            localStorageOptions = {
               prefix : options.prefix+"_"
            };

        app.provide('storage', localStorage);
    }
};

export { localstorage as default, localStorageGet, localStorageRemove, localStorageSet, useLocalStorage };
