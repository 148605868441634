<template>
  <div class="m-3">
    <div class="mb-3 p-inputgroup" v-if="searchUrl">
      <InputText type="text" :value="searchText" />
      <Button label="查找" @click="onSearch"></Button>
    </div>
    <DataTable :value="data" class="p-datatable-gridlines" responsiveLayout="scroll" :total-records="total"
               data-key="id" :paginator="true" @page="onPage"
               selectionMode="single"
               lazy="true"
               
               @row-select="onRowSelect"
               :rows="pageSize">
      <Column v-for="col in columns" :field="col.name" :header="col.title" v-bind:key="col.name">
      </Column>
    </DataTable>
    <div class="mt-3 mb-3" v-if="showButton">
      <span>{{selectionText}}</span>
    </div>
    <div v-if="showButton">
      <Button class="mb-2 w-full" label="确定" @click="onAccept" :disabled="disabled"></Button>
    </div>
    <div v-if="showButton">
      <Button class="w-full" label="取消" @click="onCancel"></Button>
    </div>
  </div>
</template>

<script>
import request from "local-vue-common/request/request";
import {buildUrl} from "local-vue-common/tools/tools";

export default {
  name: "PickTable",

  props: {
    dataUrl: {},
    searchUrl: {},
    columns: {},
    showButton : {
      default : false
    }
  },
  mounted() {
    this.loadData()
  },
  data() {
    return {
      data: [],
      loading:false,
      total : 0,
      selection: '',
      selectionText: '',
      searchText: '',
      pageNo:1,
      pageSize:5,
      disabled:true
    }
  },
  methods: {

    loadData() {
      let url = buildUrl(this.dataUrl, {
        page_no: this.pageNo,
        page_size: this.pageSize
      });
      this.loading = true;
      request.get(url).then(resp => {
        this.data = resp.data.content
        //this.records.value = r.records;
        this.total = resp.data.total;
        this.loading = false;
      })

    },
    onSearch() {

      if ( this.searchText.length > 0  ) {
         request.post(this.searchUrl,{
            searchText : this.searchText
         }).then(resp=>{
             this.data = resp.data.content;
             this.total = resp.data.total;
         })
      }
    },

    onPage(event) {
        if ( event.page ) {
            //page is zero base  server is 1 base
            this.pageNo = event.page+1
            this.loadData()
        }
    },
    onRowSelect(event) {
      this.selection = event.data
      this.selectionText = this.selection.name
      this.disabled=false
      if (this.selection)
        this.$emit('select', this.selection)

    },

    onAccept() {
      if (this.selection)
        this.$emit('accept', this.selection)
    },
    onCancel() {
      this.$emit('cancel');
    }

  }
}
</script>

<style scoped>

</style>