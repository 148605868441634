//import {DictManager} from "@/commons/services/DictManager";

export default {
  storeConfig: {
    baseUrl: "/crud/role",
  },
  getDisplayText: (data, name) => {
    const v = data[name];
    if (name == "permissions") {
      return data.permissionNames;
    }
    return v;
  },
  createEnabled: false,
  deleteEnabled: false,
  fields: [
    {
      name: "id",
      title: "角色",
      editComponent: "DisplayField",
      createEnabled: false,
    },
    {
      name: "name",
      title: "角色名",
      editComponent: "TextField",
    },
    {
      name: "permissions",
      title: "权限",
      listDisable: true,
      editComponent: "PopupSelectField",
      editComponentConfig: {
        selectPanelName: "RolePermissionPanel",
        selectButtonLabel: "===",
        textName: "permissionNames",
      },
    },
    {
      name: "permissionNames",
      title: "权限",
      editComponent: "",
    },
  ],
};
