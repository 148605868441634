<script setup>
import {
  AuthorityService as LoginService,
  getLoginSaveUrl,
} from "local-vue-common/authority/authority";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { loadProductConfigRef } from "@/commons/productConfig";
const router = useRouter();
// eslint-disable-next-line no-unused-vars,no-undef
const props = defineProps({
  haveVerifyCode: {
    default: false,
  },
});

// eslint-disable-next-line no-unused-vars
import loginImg from "@/assets/images/login-03.png";

const username = ref();
const password = ref();
const verifyCode = ref();
const message = ref();

function loginHandler() {
  message.value = "";
  const data = {
    username: username.value,
    password: password.value,
    verifyCode: verifyCode.value,
  };
  LoginService.login(data).then((ret) => {
    //console.log("===login ok");
    message.value = ret.message;
    if (ret.success) {
      router.push({ path: getLoginSaveUrl() });
    }
  });
}
const productConfig = ref({});

onMounted(() => {
  loadProductConfigRef(productConfig);
});
</script>

<template>
  <div
    class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div class="flex flex-column align-items-center justify-content-center">
      <div style="padding: 0.3rem">
        <div
          class="w-full surface-card py-8 px-5 sm:px-8 align-items-center justify-content-center"
        >
          <div class="text-center mb-5">
            <img :src="loginImg" alt="Image" width="320" class="mb-3" />

            <div class="text-900 text-3xl font-medium mb-3">
              {{ productConfig.title }}
            </div>
            <span class="text-600 font-medium">{{
              productConfig.companyName
            }}</span>
          </div>

          <div>
            <label
              for="username"
              class="block text-700 text-xl font-medium mb-2"
              >用户</label
            >
            <InputText
              id="username"
              type="text"
              class="w-full mb-3"
              inputClass="w-full-1"
              v-model="username"
            />

            <label
              for="password"
              class="block text-700 font-medium text-xl mb-2"
              >密码</label
            >
            <InputText
              id="password"
              type="password"
              v-model="password"
              class="w-full mb-3"
              inputClass="w-full-1"
            ></InputText>

            <div
              class="flex align-items-center justify-content-between mb-5 gap-5"
            >
              <span class="p-error">{{ message }}</span>
            </div>
            <Button
              label="登录"
              @click="loginHandler"
              class="w-full p-3 text-xl"
            ></Button>
          </div>
        </div>
      </div>
      <div>
        <a class="text-600" :href="productConfig.icpLink">{{
          productConfig.icpName
        }}</a>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
