export default {
  storeConfig: {
    baseUrl: "/crud/notifyPipeline",
  },
  fields: [
    {
      name: "id",
      title: "id",
      editComponent: "DisplayField",
      createEnabled: false,
    },
    {
      name: "name",
      title: "name",
      editComponent: "TextField",
    },
    {
      name: "pipelineId",
      title: "管线",
      editComponent: "TextField",
    },
  ],
};
