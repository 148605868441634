export default {
  storeConfig: {
    baseUrl: "/crud/dataPointInfo",
  },

  getDisplayText: (data, name) => {
    const v = data[name];
    return v;
  },

  fields: [
    {
      name: "id",
      title: "id",
      editComponent: "DisplayField",
      createEnabled: false,
    },
    {
      name: "name",
      title: "name",
      editComponent: "TextField",
    },

    {
      name: "ratio",
      title: "系数",
      editComponent: "TextField",
    },
    {
      name: "baseValue",
      title: "基值",
      editComponent: "TextField",
    },
    {
      name: "deviceId",
      title: "所属设备",
      editComponent: "PopupSelectField",
      editComponentConfig: {
        textName: "deviceName",
        valueName: "deviceId",
        selectPanelName: "DataTableSelectPanel",
        selectButtonLabel: "===",
        selectPanelConfig: {
          textName: "name",
          valueName: "id",
          dataUrl: "/crud/deviceInfo/find",
          searchUrl: "/crud/deviceInfo/find",
          columns: [
            {
              name: "id",
              title: "id",
            },
            {
              name: "name",
              title: "name",
            },
          ],
        },
      },
    },
    {
      name: "deviceName",
      title: "设备名",
      editComponent: "",
      createEnabled: false,
    },
    {
      name: "valueIndex",
      title: "值地址",
      editComponent: "TextField",
    },
    {
      name: "valueType",
      title: "值类型",
      editComponent: "TextField",
    },
    {
      name: "minValue",
      title: "最小值",
      editComponent: "TextField",
    },
    {
      name: "maxValue",
      title: "最大值",
      editComponent: "TextField",
    },
  ],
};
