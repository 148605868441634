class ShareData {

    constructor() {
        this.map = {};
    }


    get(key,defaultValue) {
       const ret = this.map[key];
       if ( ret == undefined || ret == null )
           return defaultValue
        return ret;
    }

    set(key,obj) {
        this.map[key]=obj;
    }

}

//export const CrudShareData = new ShareData();
//export const RouterShareData = new ShareData();

export { ShareData };
