////import SimpleCrudTable from "@common-components/table/SimpleCrudTable";
import RolePermissionPanel from "@common-components/form/RolePermissionPanel";
import PopupSelectField from "@common-components/form/PopupSelectField";
import SelectField from "@common-components/form/SelectField";
import TextField from "@common-components/form/TextField";
import DisplayField from "@common-components/form/DisplayField";
import CheckboxField from "@common-components/form/CheckboxField";

import PickTable from "@common-components/table/PickTable";
import DataTableSelectPanel from "@common-components/form/DataTableSelectPanel";
import PipelineNodePanel from "@/components/form/PipelineNodePanel";
import PipelineParamPanel from "@/components/form/PipelineParamPanel";
import SelectFieldV1 from "@/components/form/SelectFieldV1.vue";
import PipelineUserPanel from "@/components/form/PipelineUserPanel.vue";
import PipelineParamPanelV1 from "@/components/form/PipelineParamPanelV1.vue";
let saveApp = null;
function installComps() {
  const app = saveApp;

  app.component("RolePermissionPanel", RolePermissionPanel);

  app.component("PopupSelectField", PopupSelectField);
  app.component("PickTable", PickTable);
  app.component("DataTableSelectPanel", DataTableSelectPanel);

  app.component("PipelineNodePanel", PipelineNodePanel);
  app.component("PipelineParamPanel", PipelineParamPanel);
  app.component("DisplayField", DisplayField);
  app.component("TextField", TextField);
  app.component("SelectField", SelectField);
  app.component("CheckboxField", CheckboxField);
  app.component("SelectFieldV1", SelectFieldV1);
  app.component("PipelineUserPanel", PipelineUserPanel);
  app.component("PipelineParamPanelV1", PipelineParamPanelV1);
}

export default {
  install: (app) => {
    saveApp = app;
    //addLoginSuccessHandler(installComps);
    installComps();
  },
};
