<template>

  <Dialog class="col-12 md:col-6 select-dialog" v-model:visible="showSelectDialog" :modal="true"  :closable="false" >
    <template #header>
      <h2>{{title}}</h2>
    </template>
    <div class="grid">
      <div class="col-12">
        <div class="p-fluid ">

          <div class="w-full mb-3 select-role-permission">

            <PickList v-model="selections" data-key="id" list-style="height:80%"
            :showSourceControls="false" :showTagetControls="false"
                      @selection-change="selectionChange">

              <template #sourceheader>
                可选
              </template>
              <template #targetheader>
                当前
              </template>
              <template #item="slotProps">
                <span>{{ slotProps.item.name }}</span>
              </template>
            </PickList>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="grid">
        <div class="col text-center">
          <Button label="确定" @click="acceptSelect" class="w-6"></Button>
        </div>
        <div class="col text-center">
          <Button label="取消" @click="cancelSelect" class="w-6"></Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import request from "local-vue-common/request/request";

export default {
  name: "RolePermissionPanel",
  props: {
    record: {},
    name: {},
    title:{},
    editMode: {}
  },
  data() {
    return {
      showSelectDialog: false,
      selections: [],
      current: []
    }
  },

  watch: {
    record() {
      this.initList()
    },
    selections() {

    }
  },
  mounted() {
    this.showSelectDialog = true;
    this.initList();
  },
  methods: {
    initList() {
      //if ( this.editMode == "update") {
      //mgr/role/editPermissions/
      const url = "/api/mgr/role/editPermissions2";
      request.post(url, {
        'current': this.record.permissions ? this.record.permissions : []
      }).then((resp) => {
        const c = resp.data.content;
        this.selections = [c.available, c.current]
        //console.log("===init selections", this.selections)
        //this.targetItems =  c.current;
        //this.sourceItems =  c.available;
      })
      // }
    },

    getCurrent() {
      const current = this.selections[1];
      let result = []
      for (let i = 0; i < current.length; i++) {
        result.push(current[i].id)
      }
      return result;
    },

    getCurrentName() {
      const current = this.selections[1];
      let result = []
      for (let i = 0; i < current.length; i++) {
        result.push(current[i].name)
      }
      return result;
    },
    close() {
      this.showSelectDialog = false
    },
    selectionChange() {
      //console.log("===selectionChange", this.selections)
    },
    cancelSelect() {
      this.close()
      //console.log("cancel===")
      this.$emit('cancel')
    },
    acceptSelect() {
      this.close()
      //console.log("===RolePermission accept ", this.getCurrent(), this.getCurrentName())
      this.$emit('accept', this.getCurrent(), this.getCurrentName())
    },
  }

}
</script>

<style scoped>

</style>