<template>
  <div class="field">

    <label>{{ label }}</label>
    <Dropdown :name="name" :options="selections" option-label="name" option-value="value"
              :model-value="selectValue"
              @change="selectValueChange"
    />

  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import {CrudShareData} from "local-vue-common/crud/crud";

export default {
  name: "SelectField",
  components: {
    Dropdown
  },
  props: {
    name: {},
    label: {},
    value: {},
    record: {},
    editMode: {},
    selections: {}
  },
  data() {
    return {
      initFlag: true,
      localRecord : {},
      selectValue: ""
    }
  },

  watch: {
    record(v, oldVal) {
      if (v == oldVal)
        return;
      this.selectValue = this.getFieldValue();
      //console.log("===SelectField watch", v, oldVal, this.name, this.selectValue)
    }

  },

  mounted() {
      this.selectValue = this.getFieldValue()
      //console.log("=== this.selectValue ", this.selectValue, this.localRecord);
  },

  methods: {

    getFieldValue() {
      var fieldValue;

      if (this.initFlag == undefined)
        this.initFlag = true;
      //console.log("===SelectField ", this.name, this.initFlag, this.editMode, this.value, this.record)
      //let initValue = null;
      if (this.initFlag && this.editMode == 'create') {


        this.localRecord[this.name] = this.value
        fieldValue = this.value;       
      }

      {
          if ( this.record ) {

              const value = this.record[this.name];
              //if ( this.name == "checkFlag") {
              //    debugger
              //}
              //console.log("===SelectField10 get record value", this.name, value);  
              if (!(value == null)) {
                  this.localRecord[this.name] = value
                  fieldValue = value;

              }
              //if ( this.name == "checkFlag") {
              //  console.log("===SelectField11 get record value", this.name, value, this.localRecord[this.name], fieldValue );  
              //
              //}              
          }

      }

      /*
      if (this.record && ) {

        this.localRecord[this.name]=this.record[this.name]
        //console.log("===SelectField get by record", this.record)
        //return this.record[this.name]
      }

       */
       this.initFlag = false
       return fieldValue
       //this.localRecord[this.name];
    },
    getStoreVal() {
      let store = CrudShareData.get(this.storeId)
      if (!store)
        return "";
      const r = store.getCurrentRecord();

      //console.log("===SelectField get by store", r)
      return r[this.name]
    },

    selectValueChange(event) {

      this.selectValue = event.value;
      this.$emit("valueChange", this.selectValue, this.name)
    }
  }
}
</script>

<style scoped>

</style>