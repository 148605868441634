import { createApp } from "vue";

import PrimeVue from "primevue/config";

//#import "@/assets/styles/o.scss";
import "@/assets/sakai/styles.scss";

import startPrimevue from "@/commons/startPrimevue";
import startRouter from "./router/startRouter";
import startCommon from "@/commons/startCommon";
import startEChart from "@/commons/startEChart";

import { createPinia } from "pinia";
import App from "./App.vue";
import log from "loglevel";
import appInstance from "@/commons/AppInstance";
import PrimevueLocale from "@/commons/PrimevueLocale";

//import {setUseMock} from "local-vue-common/request/request"
//import {setupMocks} from "./services/setupmock.js"

log.info("===start app===");
const pinia = createPinia();
const app = createApp(App);

app.use(appInstance);
app.use(pinia);
app.use(PrimeVue, {
  locale: PrimevueLocale,
});
app.use(startPrimevue);
app.use(startCommon);
app.use(startRouter, {});
app.use(startEChart);
app.mount("#app");
