//let loadFlag=false;

const configUrl = "/public/product";
//let productConfig = {};
import request from "local-vue-common/request/request";
export function loadProductConfig() {
  return request.get(configUrl);
  /*
        .then(resp=>{

        if ( resp.success) {
            loadFlag=true
            productConfig = resp.data.content;
        }
    })
    */
}

export function loadProductConfigRef(v) {
  request.get(configUrl).then((resp) => {
    v.value = resp.data;
  });
}

//export const cfg = productCo
//nfig;

/*
import {defineStore} from "pinia";
import {ref} from "vue";
import {currentPathName, getBreadcrumbItems, notHome} from "@/router/RouteStore";

export const routeStore = defineStore("routeStore", {
    state: () => {
        const notHomeFlag = ref(notHome());
        const currentPath = ref(currentPathName());
        const breadcrumbItems = ref(getBreadcrumbItems());
        return {
            notHomeFlag,
            currentPath,
            breadcrumbItems,
        };
    },
    getters: {
        //notHome1(state) { return state.notHome },
        //notHome2() {
        //    return this.notHome2
        // }
    },
});
*/
