//import router from "local-vue-common/router/router"
import { createRouter, createWebHistory } from "vue-router";
//import { routeStore } from "./RouteStore";

//import {getRouteModule } from "local-vue-common/tools/tools"

import defaultRouteService from "./RouteService";

import homeRoutes from "./routes/00-home";
import userRoutes from "./routes/01-user";
import viewRoutes from "./routes/02-view";
import deviceRoutes from "./routes/03-device";

let routes = [];
// eslint-disable-next-line
//const store = routeStore();

homeRoutes(routes);
userRoutes(routes);
viewRoutes(routes);
deviceRoutes(routes);

// eslint-disable-next-line no-unused-vars
//let routeRoot = "";

let routeService = defaultRouteService;
/*
 {
    // eslint-disable-next-line no-unused-vars
    checkRoute : function(to,from) {
        return true
    } 
}
*/

export default {
  install: (app) => {
    const router = createRouter({
      history: createWebHistory(),
      routes: routes,
    });
    //if ( options.routeService ) {
    //    routeService = options.routeService
    //}
    //routeRoot = options.root;
    // eslint-disable-next-line no-unused-vars
    router.beforeEach((to, from) => {
      // ...
      // 返回 false 以取消导航
      return routeService.checkPath(to, from);
    });
    // eslint-disable-next-line no-unused-vars
    router.afterEach((to) => {
      //console.log("===afterEach", to);
      routeService.savePath(to);
    });

    app.use(router);
  },
};
