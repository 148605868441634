import VueStorage from "local-vue-common/localstorage/localstorage";
import Authority from "local-vue-common/authority/authority";
import startCrudConfig from "./startCrudConfig";
import pipeCheckParams from "./PipelineCheckParams";
import startComponents from "./startComponents";

export default {
  install: (app) => {
    app.use(VueStorage, { prefix: "wt" });
    app.use(Authority);
    app.use(startCrudConfig);
    app.use(startComponents);
    app.use(pipeCheckParams);
  },
};
