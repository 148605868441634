//import { addRoute } from "@/commons/services/tools";

//import HomePage from "@/pages/HomePage";
import LoginPage from "@/pages/LoginPageN";
import NotFound from "@common-components/notfoundpage/NotFound";
import { routeStore } from "@/router/RouteStore";

/*
const testMenuItems = [
  {
    label: "首页",
  },
  {
    label: "数据浏览",
    items: [{ label: "报警查询" }, { label: "日曲线" }],
  },
  {
    label: "设备参数",
  },
  {
    label: "系统参数",
    items: [
      {
        label: "用户设置",
      },
      {
        label: "角色设置",
      },
    ],
  },
];
*/

export default function add_routes(routes) {
  routes.push({
    path: "/",
    name: "home",
    component: () => import("@/pages/HomePageN.vue"),
    beforeEnter1: () => {
      const store = routeStore();
      store.$reset();
      return true;
    },
    props: {
      //menuItems: testMenuItems,
      menuUrl: "/authority/menu",
    },
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "",
        name: "DashboardPage",
        component: () => import("@/pages/DashboardPage.vue"),
        meta: {
          title: "首页",
        },
      },

      {
        path: "/sso/redirect/*",
        redirect: "/",
      },

      {
        path: "/admin",
        name: "admin-page",
        component: () => import("@/pages/AdminPage.vue"),
        meta: {
          title: "管理员控制台",
          roles: ["admin"],
        },
      },

      { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
    ],
  });

  routes.push({
    path: "/login",
    name: "login",
    component: LoginPage,
    props: {
      haveVerifyCode: false,
    },
  });
}
