import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { setTokenHeader, parseTokenHeader, checkResponseError } from '../authority/authority.js';

let useMockFlag = false;
let mock = null;
let globalService = null;

function isUseMock() {
  return useMockFlag
}
function setUseMock() {
  useMockFlag = true;
  mock = new MockAdapter(globalService);
  console.log("===create MockAdapter");
}

function getMock() {
  console.log('set post');
  return mock
}

const defaultOptions = {
  checkResponseError: true,
  timeout: 5000,
  logError : false
};

function createQuiteRequest() {

   let opts = defaultOptions;
   opts.checkResponseError=false;
   return createRequest(opts)
}

function createRequest(options) {

  options = options ? options: defaultOptions; 

  const service = axios.create({
    //baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: options.timeout
  });


  service.interceptors.request.use(
    config => {
      setTokenHeader(config.headers);
      return config
    },
    error => {
      //do something with request error
      if ( options.logError)
          console.log("req error", error); // for debug
      return Promise.reject(error)
    }
  );

  // response interceptor
  service.interceptors.response.use(
    response => {

      parseTokenHeader(response.headers);
      return response;
    },
    function (error) {
      if ( options.logError )
         console.log('res error', arguments); // for debug
      if (options.checkResponseError) {
        checkResponseError(error);
      }
      return Promise.reject(error)
    }
  );

  return service;
}
if (!globalService) {

  globalService = createRequest();
  console.log("===create globalRequestService");
}

var globalService$1 = globalService;

export { createQuiteRequest, createRequest, globalService$1 as default, getMock, isUseMock, setUseMock };
