import { CrudConfigManager } from "local-vue-common/crud/crud";

const configFiles = require.context("./crudConfig", true, /\.js$/);
configFiles.keys().reduce((modules, modulePath) => {
  const configName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = configFiles(modulePath);
  //console.log("===crudConfig", configName, value.default);
  CrudConfigManager.addConfig(configName, value.default);
  return modules;
}, {});

export default {
  // eslint-disable-next-line no-unused-vars
  install: (app, config) => {},
};
