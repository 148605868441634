<template>
  <Dialog class="col-12 md:col-6 select-dialog" v-model:visible="showSelectDialog" :modal="true" :closable="false">
    <template #header>
      <h3>{{ title }}</h3>
    </template>
    <div class="grid">
      <div class="col-12">
        <div class="p-fluid ">

          <div class="w-full mb-3 ">

            <pick-table :columns="columns" :dataUrl="dataUrl" :searchUrl="searchUrl" @select="selectHandler"/>

          </div>
        </div>
      </div>
    </div>

    <template #footer>

      <div class="grid">

        <div class="col-12 text-center">
          <span>{{ selectText }}</span>
        </div>

        <div class="col text-center">
          <Button label="确定" @click="okHandler" class="w-6"></Button>
        </div>
        <div class="col text-center">
          <Button label="取消" @click="cancelSelect" class="w-6"></Button>
        </div>

      </div>

    </template>
  </Dialog>
</template>

<script>
import request from "local-vue-common/request/request";
import PickTable from '../table/PickTable.vue';
import valueToRaw from "@/commons/valueToRaw"

export default {
  components: {PickTable},
  name: "DataTableSelectPanel",
  props: {
    dataUrl: {},
    searchUrl: {},
    columns: {},
    title: {
      default: "???"
    },
    valueName: {},
    textName: {}
  },
  data() {
    return {
      showSelectDialog: false,
      selection: null,
      selectText: ""
    }
  },
  computed: {},
  watch: {

    selections() {

    }
  },
  mounted() {
    this.showSelectDialog = true;

  },
  methods: {
    initList() {

    },
    close() {
      this.showSelectDialog = false
    },
    selectHandler(selection) {
      this.selection = selection;
    },
    okHandler() {
      this.close()
      if (!this.selection) {
        return;
      }
      const value = this.selection[this.valueName];
      const valueText = this.selection[this.textName];
      this.$emit('accept', value, valueText)
    },
    cancelSelect() {
      this.close()
      //console.log("cancel===")
      this.$emit('cancel')
    },
    acceptSelect(selection) {
      this.close()
      this.$emit('accept', selection)
    },
  }

}
</script>

<style scoped>

.p-dialog-header {
  padding: 0.5rem;
}

</style>