function locationUrl(url) {
    if (window.navigate != null)
        window.navigate(url);
    else
        window.location.href = url;
}

let useMockFlag = null;
function useMock() {
    if (useMockFlag != null)
        return useMockFlag

    try {
        if (process.env.USE_MOCK) {
            useMockFlag = true;
            return true
        }

    } catch (e) {
        console.log(e);
    }
    useMockFlag = false;
    return false
}

function isInDev() {
    try {
        let v = false;
        if (window['webpackHotUpdate'])
            v = true;
        if (v)
            console.log("in develop");

        return v;
    }
    catch (e) {
        console.log(e);
    }

    return false;
}

function addRouteChildren(item, routes, childRoute) {
    if (!item.children) {
        item.children = [];
    }
    for (let i = 0; i < childRoute.length; i++) {
        item.children.push(childRoute[i]);
    }
}
function addRoute(path, routes, childRoute) {

    for (let i = 0; i < routes.length; i++) {
        const item = routes[i];
        //if ( item.path == "/") {
        //    routes.push(item) 
        //    return;
        //}
        if (item.path == path) {
            addRouteChildren(item, routes, childRoute);
            return;
        }
        if (item.children) {
            addRoute(path, item.children, childRoute);
        }
    }
}

function getRouteModule(url) {

    const requireModule = require.context(url, false, /\.js$/); // 
    const api = [];

    requireModule.keys().forEach(fileName => {
        if (fileName === "./index.js") return;
        api.push(requireModule(fileName).default);
        //const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ""));
        //api[moduleName] = {
        //    ...requireModule(fileName).default
        //};
    });
    
    return api

}

function buildUrl(url, params) {
    if (url.indexOf("?") < 0) {
        url = url + "?";
    }
    else
        url = url + "&";

    let keys = Object.keys(params);
    //console.log("===keys",keys)

    for (let index = 0; index < keys.length; index++) {
        if (index > 0)
            url = url + "&";
        const k = keys[index];
        //console.log("===key v",v)
        url = url + k + "=" + encodeURIComponent(params[k]);
    }
    return url;
}

export { addRoute, buildUrl, getRouteModule, isInDev, locationUrl, useMock };
