<template>
  <router-view />
</template>
<script setup>
import { onMounted } from "vue";
import { loadProductConfig } from "@/commons/productConfig";

onMounted(() => {
  loadProductConfig().then((resp) => {
    document.title = resp.data.title;
  });
});
</script>
<style lang="scss">
#app {
  color: #2c3e50;
}
</style>
