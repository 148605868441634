<template>
  <div class="field">
    <label>{{ label }}</label>
    <Dropdown
      :name="name"
      :options="localSelections"
      :option-label="textName"
      :option-value="idName"
      :model-value="selectValue"
      @change="selectValueChange"
    />
  </div>
</template>
<script setup>
import { onMounted, ref, toRaw, watch } from "vue";
import request from "local-vue-common/request/request";
// eslint-disable-next-line no-undef
const props = defineProps({
  name: {},
  label: {},
  modelValue: {},
  record: {},
  editMode: {},
  selections: {},
  selectionsUrl: {},
  textName: {
    default: "name",
  },
  idName: {
    default: "id",
  },
  forceSelection: {
    default: true,
  },
  updateCount: {},
  testId: {
    default: "",
  },
});
// eslint-disable-next-line no-undef
const emit = defineEmits(["selectValueChange"]);

// eslint-disable-next-line no-unused-vars
const selectValue = ref();
const localRecord = ref(null);
const localSelections = ref();
const initChanged = ref(false);
function getPropRecordValue(name) {
  if (props.record) return toRaw(props.record[name]);
  return undefined;
}
function getFieldValue(initFlag) {
  //console.log("===SelectFieldV1 getFieldValue ",props.testId,)
  const name = toRaw(props.name);
  const recordValue = getPropRecordValue(name);
  const initValue = toRaw(props.modelValue);

  let value = "";
  if (initValue) {
    value = initValue;
    //console.log("===SelectFieldV1 initValue ",props.testId)
  } else if (recordValue) {
    value = recordValue;
    //console.log("===SelectFieldV1 recordValue ",props.testId)
  }

  if (!value && props.forceSelection) {
    value = localSelections.value[0][toRaw(props.idName)];
    initChanged.value = true;
    //console.log("===SelectFieldV1 forceSelection ",props.testId)
  }
  if (initFlag) {
    if (!localRecord.value) localRecord.value = {};

    //console.log("===SelectFieldV1 setRecord",props.testId)
    localRecord.value[name] = value;
  }

  if (localRecord.value) {
    const v = localRecord.value[name];
    return v;
  }
  return "";
}

function emitChange() {
  emit("valueChange", selectValue.value, props.name);
  emit("update:modelValue", selectValue.value);
}
function selectValueChange(event) {
  selectValue.value = event.value;
  emitChange();
}

function initSelect(initFlag) {
  localRecord.value = props.record;
  selectValue.value = getFieldValue(initFlag);
  if (initChanged.value) {
    emitChange();
    initChanged.value = false;
  }
}

function updateByRecord() {
  localRecord.value = props.record;
  selectValue.value = getFieldValue();
}

function updateByModelValue() {
  selectValue.value = toRaw(props.modelValue);
}

onMounted(() => {
  localSelections.value = props.selections;

  const url = toRaw(props.selectionsUrl);
  if (url && url.length > 1) {
    request.post(url, localRecord.value).then((resp) => {
      localSelections.value = resp.data;
      initSelect(true);
    });
    return;
  }
  initSelect(true);
});

watch(
  () => props.record,
  () => {
    updateByRecord();
  }
);
watch(
  () => props.modelValue,
  () => {
    updateByModelValue();
  }
);
</script>

<style scoped></style>
