export default {
  storeConfig: {
    baseUrl: "/crud/deviceInfo",
  },

  getDisplayText: (data, name) => {
    let v = data[name];
    if (name == "channelId") {
      v = v + "_ " + data.channelInfo.name;
    }
    return v;
  },

  fields: [
    {
      name: "id",
      title: "id",
      editComponent: "DisplayField",
      createEnabled: false,
    },
    {
      name: "name",
      title: "名字",
      editComponent: "TextField",
    },
    {
      name: "protocolType",
      title: "规约类型",
      editComponent: "SelectFieldV1",
      editComponentConfig: {
        selectionsUrl: "/public/dict/selections/protocolType",
        name: "protocolType",
        textName: "text",
      },
    },
    {
      name: "stationId",
      title: "站地址",
      editComponent: "TextField",
    },

    {
      name: "channelId",
      title: "通道",
      editComponent: "SelectFieldV1",
      editComponentConfig: {
        selectionsUrl: "/api/channel/selections",
        name: "channelId",
      },
    },
  ],
};
