/* eslint-disable */

import { Authority, AuthorityService,checkComponent,setLoginSaveUrl } from "local-vue-common/authority/authority"
import { saveCurrentPath,routeStore } from "./RouteStore"
import {loadCheckParams} from "@/commons/PipelineCheckParams";



const loginUri="/login"


const RouteService = {

    getSsoUrl(path) {
        /*
        const query = location.uri
        console.log("===RouteService query", query)
        const ret = query.split("?code=")
        if (ret.length < 2)
            return null;

        const code = ret[1]

         */
        const items = path.split("/")
        const code = items[items.length-1];
        const url = "/sso/check?code=" + code;
        console.log("===RouteService ssoUrl", url)
        return url;
    },

    checkPathPermission(to) {

        const permissions = to.meta.permissions;
        if ( !permissions )
            return true;

        const roles = to.meta.roles;

        const authorityInfo = Authority.info.authorityInfo;

        return checkComponent(authorityInfo,permissions,roles)


    },

    checkPath: function (to, from) {


        if (to.path.startsWith("/sso/redirect")) {

            console.log("===RouteService sso to ", to)
            const url = this.getSsoUrl(to.path);
            if (url)
                return AuthorityService.load(url, "/");
            return { path: loginUri }
        }

        if ( to.path == loginUri ) {
            return true;
        }

        //console.log("===to ", to)
        setLoginSaveUrl("")
        if (Authority.needLogin() ) {

            //setLoginSaveUrl(to.path)
            return { path: loginUri }
        }

        loadCheckParams()

        if (Authority.needLoad()) {
            //console.log("needLoad")
            return AuthorityService.load();
        }
    
        if (!this.checkPathPermission(to)) {
            return { path: '/error403' }
        }
        //currentPath = to;
        //saveCurrentPath(to)
        return true;
    },

    savePath: function (to) {

        //currentPath = to;
        saveCurrentPath(to)
        const store = routeStore();
        store.$reset()
        //console.log("===savePath ", to)
    }
}






export default RouteService;
