import SimpleCrudForm from "@common-components/table/SimpleCrudForm";
//import SimpleCrudTable from "@common-components/table/SimpleCrudTable";
import { addRoute } from "local-vue-common/tools/tools";

export default function add_routes(routes) {
  addRoute("/", routes, [
    {
      path: "/manager/device",
      name: "deviceList",
      component: () => import("@/pages/device/DeviceList"),
      props: {
        crudConfigName: "device",
      },
      meta: {
        title: "设备参数",
      },
    },
    {
      path: "/manager/device/form",
      name: "deviceForm",
      component: SimpleCrudForm,
      props: (route) => {
        return route.query;
      },
      meta: {
        title: "修改设备",
      },
    },
  ]);

  addRoute("/", routes, [
    {
      path: "/manager/datapoint",
      name: "datapointList",
      component: () => import("@/pages/datapoint/DatapointList"),
      props: {
        crudConfigName: "datapoint",
      },
      meta: {
        title: "数据点参数",
      },
    },
    {
      path: "/manager/datapoint/form",
      name: "datapointForm",
      component: SimpleCrudForm,
      //props: true
      props: (route) => {
        //console.log("===route",route.query);
        return route.query;
      },
      meta: {
        title: "修改数据点",
      },
    },
  ]);

  addRoute("/", routes, [
    {
      path: "/manager/pipeline",
      name: "pipeline",
      component: () => import("@/pages/pipeline/PipelineList2"),
      props: {
        crudConfigName: "pipeline",
      },
    },
    {
      path: "/manager/pipeline/form",
      name: "pipelineForm",
      component: SimpleCrudForm,
      //props: true
      props: (route) => {
        //console.log("===route",route.query);
        return route.query;
      },
      meta: {
        title: "修改管线参数",
      },
    },
  ]);

  /*
  addRoute("/", routes, [
    {
      path: "/manager/pipeline",
      name: "pipeline",
      component:
        //EmptyRoutePage, props : {
        //    props : {pageName:"pipeline"}
        //},
        () => import("@/pages/pipeline/PipelineSetting"),
      redirect: "/manager/pipeline/list",
      children: [
        {
          path: "/manager/pipeline/list",
          name: "pipelineList",
          component: SimpleCrudTable,
          props: {
            crudConfigName: "pipeline",
            pageSize: 6,
          },
          meta: {
            title: "管线参数",
          },
          props1: (route) => {
            //console.log("===route",route.query);
            return route.query;
          },
        },
        {
          path: "/manager/pipeline/form",
          name: "pipelineForm",
          component: SimpleCrudForm,
          //props: true
          props: (route) => {
            //console.log("===route",route.query);
            return route.query;
          },
          meta: {
            title: "修改管线参数",
          },
        },
      ],
    },
  ]);
  */

  addRoute("/", routes, [
    {
      path: "/manager/channel",
      name: "channelList",
      component: () => import("@/pages/channel/ChannelList.vue"),
      props: {
        crudConfigName: "channel",
      },
      meta: {
        title: "通道参数",
      },
    },
    {
      path: "/manager/channel/form",
      name: "channelForm",
      component: SimpleCrudForm,
      props: (route) => {
        return route.query;
      },
      meta: {
        title: "修改通道",
      },
    },
  ]);

  addRoute("/", routes, [
    {
      path: "/manager/transmit",
      name: "transmit",
      component: () => import("@/pages/transmit/TransmitPage"),
      meta: {
        title: "转发设置",
      },
    },
  ]);
}
