import { defineStore } from "pinia";
import { ref } from "vue";
import { getRouter } from "@/commons/AppInstance";
var _ = require("lodash");

let savePaths = {};
let currentPath = null;
// eslint-disable-next-line
let breadcrumbItems = []


const homeItem = {
  label: "首页",
  current: false,
  path: "/",
  split: false,
};

export function getHomeItem() {
  return homeItem;
}

export function checkRoutes() {}

export function saveCurrentPath(to) {
  //debugger
  savePaths[to.path] = to;
  currentPath = to;
  let items = [];
  let path = to.path;
  let pos = path.lastIndexOf("/");
  while (pos >= 0) {
    const parentPath = path.substring(0, pos);
    if (parentPath === "" || parentPath === "/") {
      items.push(homeItem);
      break;
    } else {
      const p = savePaths[parentPath];
      if (p) {
        items.push({
          label: p.meta.title ? p.meta.title : p.name,
          path: p.path,
          current: false,
          split: true,
        });
      }
    }
    path = parentPath;
    pos = path.lastIndexOf("/");
  }
  items = _.reverse(items);
  //console.log("===save path1 ", items);
  items.push({
    //path: to.path,
    label: to.meta.title ? to.meta.title : to.name,
    current: true,
    split: true,
  });

  // eslint-disable-next-line no-unused-vars
  const router = getRouter();
  //console.log("===saveCurrentPath ", items, router, router.hasRoute("/user"));
  // eslint-disable-next-line
    breadcrumbItems = items
}

const homePaths = ["/", "/dashboard-datapoint", ""];
export function isHome() {
  if (currentPath) {
    for (let i = 0; i < homePaths.length; i++)
      if (currentPath.path == homePaths[i]) {
        // "/" || currentPath.path == "") {
        return true;
      }
  } else return true;
  return false;
}

export function notHome() {
  return !isHome();
}

export function currentPathName() {
  if (currentPath) return currentPath.path;
  return "/";
}
export function getBreadcrumbItems() {
  return breadcrumbItems;
}

export const routeStore = defineStore("routeStore", {
  state: () => {
    const notHomeFlag = ref(notHome());
    const currentPath = ref(currentPathName());
    const breadcrumbItems = ref(getBreadcrumbItems());
    return {
      notHomeFlag,
      currentPath,
      breadcrumbItems,
    };
  },
  getters: {
    //notHome1(state) { return state.notHome },
    //notHome2() {
    //    return this.notHome2
    // }
  },
});
