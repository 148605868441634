// eslint-disable-next-line
import { addRoute } from "local-vue-common/tools/tools";
export default function add_routes(routes) {
  addRoute("/", routes, [
    {
      path: "/view/alertEvent",
      component: () => import("@/pages/alert/AlertQueryPage.vue"),
      props: {
        info: "升级中...",
      },
      meta: {
        title: "告警事项",
      },
    },
    {
      path: "/view/alertSms",
      component: () => import("@/pages/alert/AlertSmsQueryPage.vue"),
      props: {
        info: "升级中...",
      },
      meta: {
        title: "告警短信",
      },
    },
  ]);

  addRoute("/", routes, [
    {
      path: "/view/dayCurve",
      component: () => import("@/pages/chart/DayCurvePage.vue"),
      props: {
        info: "升级中...",
      },
      meta: {
        title: "日曲线",
      },
    },
    {
      path: "/view/monthCurve",
      component: () => import("@/pages/chart/MonthCurvePage.vue"),
      props: {
        info: "升级中...",
      },
      meta: {
        title: "月曲线",
      },
    },
    {
      path: "/view/pipelineCurve",
      component: () => import("@/pages/chart/PipelineCurvePage.vue"),
      props: {
        info: "升级中...",
      },
      meta: {
        title: "分析曲线",
      },
    },
    {
      path: "/manager/systemInfo",
      component: () => import("@/pages/SysInfoPage.vue"),
      props: {},
      meta: {
        title: "系统信息",
        roles: ["MANAGER"],
      },
    },
    {
      path: "/manager/alertManager",
      component: () => import("@/pages/alert/AlertManagerPage.vue"),
      props: {},
      meta: {
        title: "告警维护",
        roles: ["MANAGER"],
      },
    },
  ]);
}
