import ECharts from "vue-echarts";
import { use } from "echarts/core";

import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  install: (app) => {
    app.component("v-chart", ECharts);
  },
};
