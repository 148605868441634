let appInstance = null;
export function getApp() {
  return appInstance;
}
export function getRouter() {
  return getApp().config.globalProperties.$router;
}

export default function install(app) {
  appInstance = app;
}
