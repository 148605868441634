<template>
  <div class="field">
  <label>{{label}}</label>
  <div class="grid">
    <div v-for="item in checkItems"  :key="item.id"  class="col-12 md:col-4">
      <div class="field-checkbox mb-0">
        <Checkbox :id="item.elementId"  :name="name" :value="item.id" :modelValue="val" @input="handlerCheck"/>
        <label :for="item.elementId">{{item.name}}</label>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {CrudShareData} from "local-vue-common/crud/crud";
import request from "local-vue-common/request/request";
const _ = require('lodash')
export default {
  name: "CheckboxField",
  props : {
      name : {

      },
      items : {

      },
      itemsUrl : {

      },
      value : {
         default : undefined
      },

      record : {

      },
      label : {

      },
      idName : {
        default :'id'
      },
      textName : {
        default :'name'
      }
  },

  setup1() {
    /*
    let items_ = []
    if ( props.itemsUrl) {
        request.post( props.itemsUrl,{}).then(resp=>{
            items_ = resp.data.content;
        })
    }
    else
    {
       items_ = props.items
    }
    for(let i = 0; i < items_.length;i++) {
      const item = props.items[i];
      item.elementId = props.name + "_id_"+ i;
      items_.push( item );
    }
    return {
       items_
    } */
  },

  mounted() {
    if ( this.itemsUrl) {
      request.post( this.itemsUrl,{}).then(resp=>{
          const items_ = resp.data;
          //this.buildElementId(items_)
          this.checkItems = this.convertItems(items_)
      })
    }
    else {
      this.checkItems = this.convertItems(this.items)
      //this.buildElementId(this.checkItems)
    }
  },


  watch: {
     checkItems() {

     },
     record() {
        this.val = this.getFieldValue()
    }
  },
  data() {

      return {
          val : this.getFieldValue(),
          checkItems : []
      }
  },
  methods : {
    convertItems(items_) {
      const items = []
      for(let i = 0; i < items_.length;i++) {
        const aitem = items_[i];
        const aid = this.name + "_id_"+ i;
        //item.elementId = this.name + "_id_"+ i;
        //item.id = aitem[this.idName];
        //item.name = aitem[this.textName];

        items.push({
           elementId : aid,
           id : aitem[this.idName],
           name : aitem[this.textName]
        })      
        //debugger

      }
      return items;
    },
    buildElementId(items_) {

      for(let i = 0; i < items_.length;i++) {
        const item = items_[i];
        item.elementId = this.name + "_id_"+ i;
      }
    },
    getFieldValue() {
      if ( this.record ) {
        console.log("===SelectField get by record",this.record)
        return this.record[this.name]
      }
      return [];//this.getStoreVal();
    },
    getStoreVal() {
      let store = CrudShareData.get(this.storeId)
      if ( !store )
        return "";
      const r = store.getCurrentRecord();

      console.log("===SelectField get by store", r)
      return r[this.name]
    },
    handlerCheck(v) {
      // eslint-disable-next-line no-debugger
      // debugger
       console.log("checkboxField valueChange",v,this.name)
       this.val = v;
       this.$emit("valueChange",v,this.name)
    }

  }
}
</script>

<style scoped>

</style>