<script>
// eslint-disable-next-line no-unused-vars
import { h, resolveComponent } from "vue";
import { CrudConfigManager } from "local-vue-common/crud/crud";
import { checkComponent,getAuthorityInfo } from "local-vue-common/authority/authority"

const _ = require('lodash');


export default {
  name: "SimpleCrudForm",

  props: {
    crudConfigName: {},
    editMode: {},
    recordId: {},
    defaultRecord : {
        default:null
    }
  },


  data() {
    return {
      updateCount: 0,
      record: {},
      //changedRecord: {}
    }
  },

  setup(props) {
    //console.log("===SimpleCrudForm editMode", props.editMode)
    const crudConfig = CrudConfigManager.getConfig(props.crudConfigName);
    const fields = crudConfig.fields
    return {
      fields,
      crudConfig
    }
  },


  mounted() {

    let store = CrudConfigManager.getStore(this.crudConfigName)

    //let record = {};
    // eslint-disable-next-line no-debugger
    //debugger
    if (this.editMode == "update") {
      //console.log("===SimpleCrudForm findOne");
      store.findOne(this.recordId).then((res) => {
        this.record = res;
        //_.assign(this.record, res)
        //_.assign(this.changedRecord, this.record)
        this.updateCount++;
        //console.log("===SimpleCrudForm record", this.record)
      })
    }
    else
    {
        if ( this.defaultRecord ) {

            this.record = _.cloneDeep(toRaw(this.defaultRecord))
        }

        if ( this.crudConfig.defaultRecord) {

            this.record = this.crudConfig.defaultRecord()
        }
    }
  },

  updated() {
    //console.log("===SimpleCrudForm update");
  },

  methods: {

    getStore() {
      let store = CrudConfigManager.getStore(this.crudConfigName)
      return store;
    },
    convertFieldComponent(field) {
      return field.editComponent;
    },
    convertFieldComponentConfig(field) {
      const defaultConfig = {
        name: field.name,
        label: field.title,
        editMode: this.editMode
      }
      const config = _.assign(defaultConfig, field.editComponentConfig)
      return config;
    },
    createFieldComponent(field) {
      const authorityInfo = getAuthorityInfo()
      if ( !checkComponent(authorityInfo,
          field.permissions? field.permissions:[], field.roles ? field.roles : []) ) {
          return null;
      }


      if (!field.createEnabled && this.editMode == 'create') {
        return null;
      }
      if (!field.updateEnabled && this.editMode == 'update') {
        return null;
      }

      const componentTypeName = this.convertFieldComponent(field)
      if (!componentTypeName) {
        return null;
      }
      const config = this.convertFieldComponentConfig(field);
      config.record = this.record;
      config.updateCount = this.updateCount
      config.onValueChange = (v, name) => {
        //console.log("===SimpleCrudForm onValueChange", name, v)
        this.record[name] = v
        this.updateCount+=2
      }
      //console.log("===SimpleCrudForm createFieldComponent", field, config)
      return h(resolveComponent(componentTypeName), config)
    },
    createFields() {
      let fieldComponents = [];
      const fields = this.fields;
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const component = this.createFieldComponent(field);
        if (component == null)
          continue
        fieldComponents.push(component)
      }
      //fieldComponents.push(this.createButtonSave())
      //fieldComponents.push(this.createButtonCancel())
      return fieldComponents
    },

    //h('div', {class:'ml-3 w-3'}, [
    createButtonSave() {
      return   h(resolveComponent('Button'), {
          label: "保存",
          class:'w-3',
          onClick: () => {
            let store = this.getStore();

            //console.log("===SimpleCrudForm save", this.record)
            store.save(this.editMode, this.record).then(() => {
              this.$router.back();
            }).catch((r) => {
              alert(r)
            })
          }
        }); 
        //])
    },
    createButtonCancel() {
      return  h(resolveComponent('Button'), {
          label: "取消",
          class:'ml-3 w-3',
          onClick: () => {
            this.$router.back()
          }
        })

      /*h('div', {
        class: 'ml-3 w-3'
      }, [*/
      //])
    }

  },

  render() {
    const fieldComponents = this.createFields();
    const buttons = [];
    buttons.push(this.createButtonSave())
    buttons.push(this.createButtonCancel())
    return h(
      'div', {
      class: "grid"
    },
      [
        h('div', { class: 'col-12 md:col-6 ml-3 mt-2' }, [
          h('div', { class: 'card p-fluid m-2' }, fieldComponents)
        ])
      ],
      [
        h('div',{class:'col-12 md:col-6 ml-3 mt-2 text-center'},buttons)
      ]
      
      )
  }


}
</script>

<style scoped></style>