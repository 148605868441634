import { localStorageGet, localStorageSet, localStorageRemove } from '../localstorage/localstorage.js';
import { parseISO } from 'date-fns';
import Cookies from 'js-cookie';
import request from '../request/request.js';
import { locationUrl } from '../tools/tools.js';

class AuthorityService_ {

    constructor() {
    }

    login(data) {

        return new Promise((resolve, reject)=> {
            request.post("/login", data).then(resp => {
                //console.log("===AuthorityService login 1")
                if ( resp.data.success ) {
                    Authority.login(resp.data.content);
                    resolve({success:true});
                }
                else
                    resolve({success:false,message:resp.data.message});
                //console.log("===AuthorityService login 2")

            }).catch(()=>{
                //console.log("===AuthorityService login catch ")
                 reject();
            }
            );
            //console.log("===login ???")
        })
    }

    logout() {
        Authority.logout();
        return request.post("/logout", {})
    }

    load(url,redirect) {
        if ( !url )
           url = "/authority";
        return new Promise((resolve, reject)=> {

            request.post(url).then(resp => {

                if ( resp.data.success ) {
                    Authority.update(resp.data.content);
                    if ( redirect ) {
                        locationUrl(redirect);
                    }
                    resolve(true);
                }
                else
                    resolve({path:"/login"});

            }).catch(()=>{
                    reject();
                }
            );
        })
    }
}

const AuthorityService = new AuthorityService_();
//export AuthorityService;

const AuthorityInfoKey = 'J_AUTHORITY';
let TokenHeaderName = "Authorization";
let TokenCookieName = "Authorization";
let loginSuccessHandlers = [];
class Authority_ {

    constructor() {
        this.init();
    }

    init() {
        this.reload = false;
        this.info = {
            login: false,
            tokenInfo: {},
            authorityInfo: {}
        };
    }

    loadFromLocal() {
        const text = localStorageGet(AuthorityInfoKey);
        if (text) {
            this.info = JSON.parse(text);
            // eslint-disable-next-line no-debugger
        }
    }

    save(data) {
        //debugger
        if (data.login == 1) {
            for (let i = 0; i < loginSuccessHandlers.length; i++) {
                try {
                    loginSuccessHandlers[i]();
                }
                catch (e) {

                }
            }
        }
        this.info = data;
        localStorageSet(AuthorityInfoKey, JSON.stringify(data));
        Cookies.set(TokenCookieName, this.info.tokenInfo.token);
    }

    logout() {
        //
        //request.post("/logout", {}).then(res=>{
        //    console.log("===logout",res)
        //})
        this.clear();
        location.reload();
    }

    clear() {
        this.init();
        localStorageRemove(AuthorityInfoKey);
    }

    login(content) {
        //console.log("===Authority login 1")
        if (content.login != undefined) {
            //console.log("===Authority login 2")
            // eslint-disable-next-line no-debugger
            //debugger
            this.save(content);
        }
        //console.log("===Authority login 3")

    }

    setTokenHeader(header) {

        if (this.info.login)
            header[TokenHeaderName] = this.info.tokenInfo.token;

    }

    parseTokenHeader(header) {
        const newToken = header[TokenHeaderName];
        if (!newToken)
            return;
        if (newToken == this.info.tokenInfo.token)
            return;
        this.info.tokenInfo.token = new newToken();
        this.reload = true;
    }

    getToken() {
        if (this.info.login)
            return this.info.tokenInfo.token;
        return null;
    }

    needLoad() {
        return this.reload;
    }

    needLogin() {
        // eslint-disable-next-line no-empty
        if (this.info.login) {

            let expireTime = parseISO(this.info.tokenInfo.expireTime);
            //Date.parse(this.info.tokenInfo.expireTime)
            let now = new Date();
            // eslint-disable-next-line no-debugger
            //debugger
            console.log("expireTime", expireTime);

            if (now.getTime() > expireTime.getTime()) {
                this.logout();
                //return true;
            }

        }
        return !this.info.login;
    }

    update(content) {
        this.reload = false;
        this.save(content);
    }

    getUsername() {
        if (this.info.login) {
            return this.info.authorityInfo.displayName
            +"("+this.info.authorityInfo.username+")";
        }
        return "?"
    }

    
}

/*
export function getToken() {
    var v = localStorageGet(TokenKey);
    return v;
}

export function removeToken() {
    localStorageRemove(TokenKey)
}

export function getTokenHeaderName() {
    return "X-TOKEN"
}
*/


function havePermission(userPermission, itemPermission, haveChild) {
    const splitChar = "/";
    if (userPermission[0] == "/") {
        if (itemPermission[0] != "/")
            itemPermission = "/" + itemPermission;
    }
    if (userPermission == itemPermission)
        return true;

    if (userPermission == "*") {
        return true;
    }

    let t1;
    let t2;
    // itemPermission /a/a-1    userPermission /a
    if (userPermission.length < itemPermission.length) {

        t1 = userPermission;
        if (userPermission[userPermission.length - 1] != splitChar)
            t1 = userPermission + splitChar;
        t2 = itemPermission.substr(0, t1.length);

        return (t1 == t2);
    } else {
        //  itemPermission /a     userPermission /a/a-1
        //  构造菜单有用
        if (haveChild) {
            t1 = itemPermission + splitChar;
            t2 = userPermission.substr(0, t1.length);
            return (t1 == t2);
        }
    }
    return false;
}

function checkPermission(userPermissions, itemPermission, haveChild) {
    console.log("checkPermission", userPermissions, itemPermission);

    if (!haveChild)
        haveChild = false;

    if (!itemPermission)
        return true;

    //?
    //if (!permissions)
    //  return true;

    for (let i = 0; i < userPermissions.length; i++) {
        const userPermission = userPermissions[i];
        if (havePermission(userPermission, itemPermission, haveChild)) {
            return true;
        }
    }
    return false;
}

function haveRole(userRoles, itemRole) {
    if (!itemRole)
        return true;

    for(let r in userRoles)
        if ( r === itemRole )
             return true;
    return false;
}

// 
function checkComponent(authInfo, itemPermissions,itemRoles) {
    if (!authInfo)
        return false;
    if (authInfo.admin)
        return true;

    const userPermissions = authInfo.permissions;
    const userRoles = authInfo.roles;

    //let permissionOk=true;
    //let roleOk=true;    
    
    for(var i = 0; i < itemPermissions.length;i++) {
        const itemPermission = itemPermissions[i];
        if ( !checkPermission(userPermissions,itemPermission)) {
            return false;
        }
    }
    for(var i = 0; i < itemRoles.length;i++) {
        const itemRole = itemRoles[i];
        if ( !haveRole(userRoles,itemRole)) {
            return false;
        } 
    }

    return true;
    //return checkPermission(authInfo.permissions, itemPermission)
}

function checkHaveRole(role) {

    const info = getAuthorityInfo();
    const userRoles = info.roles;    
    return haveRole(userRoles,role)
}

function checkPath(authInfo, path) {
    console.log("checkPath", authInfo, path);
    if (!authInfo)
        return false;
    if (authInfo.admin)
        return true;
    const r = checkPermission(authInfo.permissions, path);
    return r;
}

function addLoginSuccessHandler(handler) {
    loginSuccessHandlers.push(handler);
}



function setTokenHeader(header) {
    Authority.setTokenHeader(header);
}

function parseTokenHeader(header) {
    Authority.parseTokenHeader(header);
}

let responseErrorAlert = false;

function checkResponseError(error) {
    const msg = error.toString();
    if (msg.indexOf("401") >= 0) {
        if (responseErrorAlert) {
            return
        }
        responseErrorAlert = true;
        setTimeout(() => {
            alert('需要重新登录');
            location.reload();
        }, 100);
    }
}

let loginSaveUrl="";

function setLoginSaveUrl(path) {
    loginSaveUrl = path;
}
function getLoginSaveUrl() {
    if ( !loginSaveUrl)
       return "/"
    return loginSaveUrl;
}


const Authority = new Authority_();

function getAuthorityInfo() {
    return Authority.info.authorityInfo 
}


var authority = {
    install: (app, options) => {

        if (options && options.tokenHeaderName)
            TokenHeaderName = options.tokenHeaderName;
        Authority.loadFromLocal();

        //const store = useStore();
        // eslint-disable-next-line no-debugger

        //options.store.registerModule('authority', authorityStore)

        app.provide('authority', Authority);

    }
};

export { Authority, AuthorityService, addLoginSuccessHandler, checkComponent, checkHaveRole, checkPath, checkPermission, checkResponseError, authority as default, getAuthorityInfo, getLoginSaveUrl, parseTokenHeader, setLoginSaveUrl, setTokenHeader };
