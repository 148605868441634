import SimpleCrudForm from "@common-components/table/SimpleCrudForm";
//import SimpleCrudTable from "@common-components/table/SimpleCrudTable";
import { addRoute } from "local-vue-common/tools/tools";

export default function add_routes(routes) {
  addRoute("/", routes, [
    {
      path: "/manager/department",
      name: "department",
      component: () => import("@/pages/department/DepartmentPage"),
      meta: {
        title: "部门设置",
      },
    },
  ]);

  addRoute("/", routes, [
    {
      path: "/manager/user",
      name: "userList",
      component: () => import("@/pages/user/UserList"),
      props: {
        crudConfigName: "user",
      },
      meta: {
        title: "用户设置",
      },
    },
    {
      path: "/manager/user/form",
      name: "userForm",
      component: SimpleCrudForm,
      props: (route) => {
        return route.query;
      },
      meta: {
        title: "修改用户",
        permssions: ["/user/create", "/user/update"],
      },
    },
  ]);
  //console.log("===routes ", routes);
  addRoute("/", routes, [
    {
      path: "/manager/role",
      name: "role",
      component: () => import("@/pages/role/RoleSetting"),
      redirect: "/manager/role/list",
      children: [
        {
          path: "/manager/role/list",
          name: "roleList",
          component: () => import("@/pages/role/RoleList"),
          props: {
            crudConfigName: "role",
          },
          meta: {
            title: "角色设置",
          },
        },
        {
          path: "/manager/role/form",
          name: "roleForm",
          component: SimpleCrudForm,
          //props: true
          props: (route) => {
            //console.log("===route", route.query);
            return route.query;
          },
          meta: {
            title: "修改角色",
          },
        },
      ],
    },
  ]);
}
