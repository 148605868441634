import { ShareData } from '../sharedata/sharedata.js';
import request from '../request/request.js';
import { buildUrl } from '../tools/tools.js';

//import { array as _array } from "lodash-es";
const _$1 = require('lodash');


class CrudStore {

    constructor(options) {

        this.ipProp = 'id';
        this.baseUrl = options.baseUrl;
        this.findOneUrl = options.findOneUrl ? options.findOneUrl : options.baseUrl + "/findOne";
        this.findUrl = options.findUrl ? options.findUrl : options.baseUrl + "/find";
        this.createUrl = options.createUrl ? options.createUrl : options.baseUrl + "/create";
        this.updateUrl = options.updateUrl ? options.updateUrl : options.baseUrl + "/update";
        this.deleteUrl = options.deleteUrl ? options.deleteUrl : options.baseUrl + "/delete";
        this.pageSize = options.pageSize ? options.pageSize : 10;
        this.cacheEnabled = options.cacheEnabled; 

        this.pageNo = 1;
        this.total = 0;
        this.records = [];
        this.fields = [];
        this.errorMessage = "";
        this.currentRecord = {};
        this.responseData = {};
        this.findParams = {};
        this.currentId = '';
    }

    save(editMode, record) {

        let url = this.createUrl;
        if (editMode == 'update') {
            url = this.updateUrl;
        }
        return new Promise((resolve, reject) => {
            request.post(url, record).then(resp => {
                if (resp.data.success) {
                    const rec = resp.data.content;
                    this.currentRecord = rec;
                    resolve(rec);
                } else {
                    this.errorMessage = resp.data.message;
                    reject(this.errorMessage);
                }
            }).catch(r => {
                reject("exception :" + r);
            });
        })

    }

    create(record) {
        return this.save('create', record)
    }

    update(record) {
        this.cleanCurrentRecord();
        return this.save('update', record)
    }


    delete(id) {
        const url = this.deleteUrl;
        this.errorMessage = "";
        return new Promise((resolve, reject)=>{
            request.post(url, {id: id}).then(resp => {
                if (resp.data.success) {
                    this.records = _$1.array.remove(this.records, (r) => (r.id == id));
                    resolve();//this.errorMessage = "";
                } else {
                    this.errorMessage = resp.data.message;
                    reject(this.errorMessage);
                }
            }).catch(r => {
                reject(r);
            });

        })



    }

    find(params) {
        let url = buildUrl(this.findUrl, {
            page_no: this.pageNo,
            page_size: this.pageSize
        });
        this.fail = false;
        return new Promise((resolve, reject) => {
            request.post(url, params).then(resp => {
                if (resp.data.success) {
                    this.records = resp.data.content;
                    this.total = resp.data.total;
                    this.errorMessage = "";
                    resolve({
                        records: this.records,
                        total: this.total});
                } else {
                    this.records = [];
                    this.errorMessage = resp.data.message;
                    reject(this.errorMessage);
                }

            }).catch(r => {
                console.log(r);
                this.records = [];
                this.fail = true;
                reject("exception");
            });

        })
    }

    getCurrentRecord() {
        return this.currentRecord
    }

    cleanCurrentRecord() {
        this.currentId = '';
    }

    findOne(id) {
        if ( this.currentId == id && this.cacheEnabled ) {
            console.log("===findOneCache");
            return new Promise((resolve) => {
                 resolve(this.currentRecord);
            })
        }
        let url =
            buildUrl(this.findOneUrl, {
                id: id,
            });
        url = this.findOneUrl + "/" + id;
        return new Promise((resolve, reject) => {
            request.post(url, {}).then(resp => {
                if (resp.data.success) {

                    this.currentRecord = resp.data.content;
                    this.currentId = this.currentRecord.id;
                    this.errorMessage = "";
                    resolve(resp.data.content);
                } else {
                    this.errorMessage = resp.data.message;
                    reject(0, this.errorMessage);
                }
            }).catch(r => {
                reject(1, r);
            });

        })
    }

    updateDisplayField(record,crudConfig) {
        const fields = crudConfig.fields;
        for(let i = 0; i < fields.length; i++) {
            if ( fields[i].updateDisplayField) {
                fields[i].updateDisplayField(record);
            }
        }
    }

    getRecords() {
        return this.records;
    }

    setFields(fields) {
        this.fields = fields;
    }

    getFields() {
        return this.fields
    }

    setPageNo(no) {
        this.pageNo = no;
    }

    setPageSize(s) {
        this.pageSize = s;
    }

    newRecord() {
        
    }
}

//import { array as _array } from "lodash-es";
require('lodash');


class QueryStore {

    constructor(options) {

        this.pageSize = options.pageSize;
        this.pageNo = 1;
    }

    setPageNo(pageNo) {
        this.pageNo = pageNo;
    }

    find(url, params) {
        let findUrl = buildUrl(url, {
            page_no: this.pageNo,
            page_size: this.pageSize
        });
        this.fail = false;
        return new Promise((resolve, reject) => {
            request.post(findUrl, params).then(resp => {
                if (resp.data.success) {
                    this.records = resp.data.content;
                    this.total = resp.data.total;
                    this.errorMessage = "";
                    resolve({
                        records: this.records,
                        total: this.total});
                } else {
                    this.records = [];
                    this.errorMessage = resp.data.message;
                    reject(this.errorMessage);
                }

            }).catch(r => {
                console.log(r);
                this.records = [];
                this.fail = true;
                reject("exception");
            });

        })
    }    
}

const _ = require('lodash');

class CrudConfigManager_ {

    constructor() {
        this.configMap = {};
        this.storeMap = {};
    }

    getConfig(name,options) {
        const cfg = _.merge(this.configMap[name],options?options:{});
        return cfg
    }

    addConfig(name, config) {

        let defaultConfig = this.configMap[name] ? this.configMap[name]:{
            editFormName : name+'Form',

            getDisplayText: (data, name) => {
                const v = data[name];
                return v
            },
            // eslint-disable-next-line no-unused-vars
            getDisplayClass:(data,name) => {
                return "";
            },
            updateEnabled : true,
            createEnabled : true,
            deleteEnabled : true,
            cacheDisabled : true
        };
        let newConfig = _.merge(defaultConfig,config);

        for(let i = 0; i < newConfig.fields.length; i++) {
            newConfig.fields[i] = this.convertField(newConfig.fields[i]);
        }
        // eslint-disable-next-line no-debugger
        //debugger
        this.configMap[name]=newConfig;

        let store = new CrudStore(newConfig.storeConfig);
        this.storeMap[name]=store;
    }

    getStore(name) {
        return this.storeMap[name];
    }

    convertField(field) {
        let defaultField = {
            createEnabled : true,
            updateEnabled : true
        };
        return _.merge(defaultField,field)
    }

}

const CrudConfigManager  = new CrudConfigManager_();

const CrudShareData = new ShareData();

export { CrudConfigManager, CrudShareData, CrudStore, QueryStore };
