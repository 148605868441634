export default {
  storeConfig: {
    baseUrl: "/crud/user",
  },
  title: "用户",
  fields: [
    {
      name: "id",
      title: "id",
      editComponent: "DisplayField",
      createEnabled: false,
    },
    {
      name: "domainId",
      title: "domainId",
      editComponent: "TextField",
      roles: ["ADMIN"],
    },

    {
      name: "loginName",
      title: "登录名",
      editComponent: "TextField",
    },
    {
      name: "displayName",
      title: "显示名",
      editComponent: "TextField",
    },
    {
      name: "phone",
      title: "手机号",
      editComponent: "TextField",
    },
    {
      name: "password1",
      title: "密码(空表示无需修改)",
      editComponent: "TextField",
      listDisable: true,
    },
    {
      name: "password2",
      title: "密码验证",
      editComponent: "TextField",
      listDisable: true,
    },
    {
      name: "roles",
      title: "角色",
      editComponent: "CheckboxField",
      editComponentConfig: {
        itemsUrl: "/crud/role/comboBox?dict=id_name",
        //itemsUrl : "/demo/json/test-roles",
        textName: "name",
        idName: "id",
        items1: [
          {
            name: "用户",
            id: "USER",
          },
          {
            name: "超级管理员",
            id: "ADMIN",
          },
        ],
      },
    },
  ],
};
