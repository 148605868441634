<template>
<div class="card">
   <h2>无效路径<span class="ml-3">{{this.$route.path}}</span></h2>
   <Button label="返回" @click="goBack"></Button>
</div>
</template>

<script>
export default {
  name: "NotFound.vue",
  data() {


  },
  methods : {
      goBack() {

        this.$router.back()
      }
  },
  mounted() {
    console.log("===notFound2", this.$route.path)
  },
  beforeRouteEnter() {
    console.log("===notFound1",arguments)
  },
  beforeRouteUpdate() {

      console.log("===notFound2",arguments)
  }
}
</script>

<style scoped>

</style>