const locale = {
  startsWith: "Starts with",
  contains: "Contains",
  notContains: "Not contains",
  endsWith: "Ends with",
  equals: "Equals",
  notEquals: "Not equals",
  noFilter: "No Filter",
  lt: "Less than",
  lte: "Less than or equal to",
  gt: "Greater than",
  gte: "Greater than or equal to",
  dateIs: "Date is",
  dateIsNot: "Date is not",
  dateBefore: "Date is before",
  dateAfter: "Date is after",
  clear: "Clear",
  apply: "Apply",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Add Rule",
  removeRule: "Remove Rule",
  accept: "是",
  reject: "否",
  choose: "Choose",
  upload: "Upload",
  cancel: "取消",
  dayNames: [
    "星期天",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
  ],
  dayNamesShort: ["日", "一", "二", "三", "四", "五", "六"],
  dayNamesMin: ["日", "一", "二", "三", "四", "五", "六"],
  monthNames: [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ],
  monthNamesShort: [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ],
  today: "Today",
  weekHeader: "Wk",
  firstDayOfWeek: 0,
  dateFormat: "yy-mm-dd",
  weak: "Weak",
  medium: "Medium",
  strong: "Strong",
  passwordPrompt: "Enter a password",
  emptyFilterMessage: "No results found",
  emptyMessage: "空",
};

export default locale;
