<template>
  <Dialog
    class="col-12 md:col-6 select-dialog"
    v-model:visible="showSelectDialog"
    :modal="true"
    :closable="false"
  >
    <div class="grid">
      <div class="col-12">
        <div class="p-fluid">
          <div class="field" v-if="editMode == 'update'">
            <label>管线名称</label>
            <InputText type="text" readonly :value="localRecord.name" />
          </div>

          <div class="field">
            <label>检测方法</label>
            <InputText type="text" readonly :value="getCheckMethodName()" />
          </div>

          <div class="field" v-for="param in params" v-bind:key="param.name">
            <label>{{ param.description }}</label>
            <InputText
              :name="param.name"
              type="text"
              :value="getParamValue(param.name)"
              @input="paramValueChange"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="grid">
        <div class="col text-center">
          <Button label="确定" @click="acceptSelect" class="w-6"></Button>
        </div>
        <div class="col text-center">
          <Button label="取消" @click="cancelSelect" class="w-6"></Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script setup>
//import { DictManager } from "local-vue-common/dictmanager/dictmanager";
//import { getCheckParams } from "@/commons/PipelineCheckParams";
//const _ = require("lodash");
// eslint-disable-next-line no-unused-vars,no-undef
import { onMounted, ref, toRaw } from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  record: {
    default: {},
  },
  name: {},
  editMode: {},
});

const localRecord = ref();

onMounted(() => {
  if (props.record == null)
    localRecord.value = { checkMethod: "check-close-count" };
  else localRecord.value = toRaw(props.record);

  //console.log("=== PipelineParamPanelV1.vue", props.record, localRecord.value);
});
</script>
<script>
/*
import { DictManager } from "local-vue-common/dictmanager/dictmanager";
import { getCheckParams } from "@/commons/PipelineCheckParams";
const _ = require("lodash");
export default {
  name: "PipelineParamPanel",
  props: {
    record: {},
    name: {},
    editMode: {},
  },
  data() {
    return {
      params: [],
      paramValues: {},
      localRecord: {
        default: null,
      },
      showSelectDialog: false,
    };
  },

  watch: {
    localRecord() {
      this.params = this.initParams();
      this.initParamValue();
    },
  },

  mounted() {
    this.localRecord = _.cloneDeep(this.record);
    debugger
    this.params = this.initParams();
    this.initParamValue();
    this.showSelectDialog = true;
    //this.paramValues =
  },
  methods: {
    close() {
      this.showSelectDialog = false;
    },
    cancelSelect() {
      this.close();
      //console.log("cancel===");
      this.$emit("cancel");
    },
    acceptSelect() {
      this.close();
      const v = JSON.stringify(this.paramValues);
      this.localRecord["checkParam"] = v;
      this.$emit("accept", v);
    },
    initParams() {
      //console.log("===initParams ", this.localRecord);
      const t = this.localRecord["checkMethod"];
      return getCheckParams(t);

    },
    initParamValue() {
      let initValue = {};
      for (let i = 0; i < this.params.length; i++) {
        initValue[this.params[i].name] = this.params[i].value;
      }
      this.paramValues = initValue;
      //console.log("===paramValues", this.paramValues);
      try {
        //if ( this.editMode=='update') {
        const tmp = JSON.parse(this.localRecord[this.name]);
        this.paramValues = _.merge(initValue, tmp);
        //}
      } catch (err) {
        // eslint-disable-next-line no-empty
        //this.paramValues = initValue
      }
    },

    getParam(name) {
      for (let i = 0; i < this.params.length; i++) {
        if (this.params[i].name == name) return this.params[i];
      }
      return null;
    },
    getParamValue(name) {
      return this.paramValues[name];
    },
    getParamValue1(name) {
      //console.log("===getParamValue", name);
      const param = this.getParam(name);
      if (param) return param.value;
      return "";
    },
    paramValueChange(event) {
      const name = event.target.name;
      const value = event.target.value;
      //const param = this.getParam(name)
      //if ( param )
      //    param.value = value;
      this.paramValues[name] = value;
      //console.log("===paramValueChange", name, value);
    },
    getCheckMethodName() {
      return DictManager.getDictText(
        "checkMethod",
        this.localRecord["checkMethod"]
      );
    },
  },
}; */
</script>

<style scoped></style>
